import {
  ScaleLinear,
  scaleLinear,
} from 'd3-scale';
import memoize from '../../memoize';
import {
  getSVGPoint,
  ITransformationMatrix,
} from '../../network/panZoom';
import {
  getLinearScale,
} from '../../NonConstantUtils';

interface IInput {
  svgWidth: number;
  svgHeight: number;
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;
  transformationMatrix: ITransformationMatrix;
}

interface IOutput {
  xTickValues: number[];
  yTickValues: number[];
  transformedXScale: ScaleLinear<number, number>;
  transformedYScale: ScaleLinear<number, number>;
}

export const getTicksInfoGetter = () => {
  const getXScale = memoize(getLinearScale);
  const getYScale = memoize(getLinearScale);

  return (input: IInput): IOutput => {
    const {svgWidth, svgHeight} = input;

    const {xAxisMin, xAxisMax, yAxisMin, yAxisMax, transformationMatrix} = input;
    const xScale = getXScale([xAxisMin, xAxisMax], [0, svgWidth]);
    const yScale = getYScale([yAxisMin, yAxisMax], [svgHeight, 0]);

      // First figure out what the top-left and bottom-right corners of the graphs
      // are in SVG coordinates:
    const {x: x0, y: y0} = getSVGPoint(transformationMatrix, {x: 0, y: 0});
    const {x: x1, y: y1} = getSVGPoint(transformationMatrix, {x: svgWidth, y: svgHeight});

      // Then figure out what the top-left and bottom-right corners of the graphs
      // are in terms of value plotted on the graph ("distance" for x-axis and
      // opportunity gain/complexity for y-axis)":
    const transformedXAxisMin = xScale.invert(x0);
    const transformedXAxisMax = xScale.invert(x1);
    const transformedYAxisMin = yScale.invert(y1);
    const transformedYAxisMax = yScale.invert(y0);

      // Then map them the "visible values" (based on the transformation
      // matrix) onto the SVG canvas:
    const transformedXScale = scaleLinear<number, number>()
                                .domain([transformedXAxisMin, transformedXAxisMax])
                                .range([0, svgWidth]);
    const transformedYScale = scaleLinear<number, number>()
                                .domain([transformedYAxisMin, transformedYAxisMax])
                                .range([svgHeight, 0]);

      // Figure out the ticks based on these new scales:
    const xTickValues = transformedXScale.ticks();
    const yTickValues = transformedYScale.ticks();

    return {
        xTickValues, yTickValues,
        transformedXScale, transformedYScale,
      };
  };
};
