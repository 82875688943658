import React from 'react';
import styled from 'styled-components';
import {
  ITransformationMatrix,
} from '../../network/panZoom';
import {
  offChartTextStyling,
  XAxisContainer,
  YAxisContainer,
} from '../Grid';
import Tick from './Tick';
const styles = require('./ticks.css');
const sharedStyles = require('../container/axisElement.css');
import classnames from 'classnames';
import memoize from '../../memoizeWithJSON';
import {
  getTicksInfoGetter,
} from './getTicksInfo';
import { Theme } from './index';

const XAxis = styled(XAxisContainer)`
  ${offChartTextStyling}
`;
const YAxis = styled(YAxisContainer)`
  ${offChartTextStyling}
`;

interface IProps {
  transformationMatrix: ITransformationMatrix;
  svgWidth: number;
  svgHeight: number;
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;
  zIndex: number;
  theme: Theme;
}

export default class extends React.PureComponent<IProps, {}> {
  private getTicksInfo = memoize(getTicksInfoGetter());

  render() {
    const {
      svgWidth, svgHeight,
      xAxisMin, xAxisMax,
      yAxisMin, yAxisMax,
      transformationMatrix,
      zIndex, theme,
    } = this.props;

    const {
      xTickValues,
      yTickValues,
      transformedXScale,
      transformedYScale,
    } = this.getTicksInfo({
      svgWidth, svgHeight,
      xAxisMin, xAxisMax,
      yAxisMin, yAxisMax,
      transformationMatrix,
    });

    const xLabelClass = theme === Theme.Explore ? styles.xLabelExplore : styles.xLabelCountryPages;

    const xTickClassName = classnames(sharedStyles.element, styles.xPosition, styles.tick, styles.xTick);
    const yTickClassName = classnames(sharedStyles.element, styles.yPosition, styles.tick, styles.yTick);
    const xTickLabelClassName = classnames(sharedStyles.element, styles.xPosition, xLabelClass);
    const yTickLabelClassName = classnames(sharedStyles.element, styles.yPosition, styles.yLabel);

    const xTicks = xTickValues.map(value => (
      <Tick
        coord={transformedXScale(value)}
        label={value.toString()}
        key={`x-tick-${value}`}
        tickClassName={xTickClassName}
        tickLabelClassName={xTickLabelClassName}/>
    ));

    const yTicks = yTickValues.map(value => (
      <Tick
        coord={transformedYScale(value)}
        label={value.toString()}
        key={`y-tick-${value}`}
        tickClassName={yTickClassName}
        tickLabelClassName={yTickLabelClassName}/>
    ));

    const style: React.CSSProperties = {
      zIndex,
    };

    return (
      <>
        <YAxis style={style}>
          {yTicks}
        </YAxis>
        <XAxis style={style}>
          {xTicks}
        </XAxis>
      </>
    );
  }
}
