// Given an `inputFunction`, returns a memoized version that will:
// - recompute if the serialization of its arguments has changed
// - return the last cached result if the serialization of its arguments are the
// same as in the last call.
let memoize: <T>(inputFunction: T) => T;

memoize = ((func: any) => {
  let lastArgs: any = null;
  let lastResult: any = null;
  let recomputations = 0;
  // we reference arguments instead of spreading them for performance reasons
  function memoized() {
    if (JSON.stringify(arguments) !== lastArgs) {
      // apply arguments instead of spreading for performance.
      lastResult = func.apply(null, arguments);
      if (process.env.NODE_ENV !== 'production') {
        recomputations++;
      }
    }

    lastArgs = JSON.stringify(arguments);
    return lastResult;
  }
  if (process.env.NODE_ENV !== 'production') {
    (memoized as any).recomputations = () => recomputations;
    (memoized as any).resetRecomputations = () => recomputations = 0;
  }
  return memoized;
// Force typying here because it's too complex for typescript:
}) as any;

export default memoize;
