import React from 'react';
import styled from 'styled-components';
import {
  applyMatrixToPoint,
  ITransformationMatrix,
} from '../../network/panZoom';
import {
  IProcessedNode,
} from '../../workerStore/feasibility/Utils';
import {
  XAxisContainer,
  YAxisContainer,
} from '../Grid';
import ColoredBar from './ColoredBar';
import {
  Axis,
} from './Utils';
const styles = require('./bars.css');
const sharedStyles = require('./axisElement.css');
import classnames from 'classnames';

const XColoredBarsContainer = styled(XAxisContainer)`
  overflow: hidden;
`;

const YColoredBarsContainer = styled(YAxisContainer)`
  overflow: hidden;
`;

const yClassName = classnames(sharedStyles.element, styles.yAxis);
const xClassName = classnames(sharedStyles.element, styles.xAxis);

interface IProps {
  transformationMatrix: ITransformationMatrix;
  nodes: IProcessedNode[];
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {nodes, transformationMatrix} = this.props;

    const yAxisBars: JSX.Element[] = [];
    const xAxisBars: JSX.Element[] = [];
    for (const {x, y, id, color} of nodes) {
      const {x: transformedX, y: transformedY} = applyMatrixToPoint(transformationMatrix, x, y);
      yAxisBars.push(
        <ColoredBar
          coord={transformedY} color={color}
          axis={Axis.Y} className={yClassName}
          key={id}/>,
      );

      xAxisBars.push(
        <ColoredBar
          coord={transformedX} color={color}
          axis={Axis.X} className={xClassName}
          key={id}/>,
      );
    }

    return (
      <>
        <YColoredBarsContainer>
          {yAxisBars}
        </YColoredBarsContainer>
        <XColoredBarsContainer>
          {xAxisBars}
        </XColoredBarsContainer>
      </>
    );
  }
}
