import TweenLite from 'gsap/TweenLite';
import throttle from 'lodash-es/throttle';
import React from 'react';
import styled from 'styled-components';
import {
  getIdentityTransformMatrix,
  getScaleFactor,
  IPoint,
  ITransformationMatrix,
  updatePanning,
  zoom,
} from '../../network/panZoom';
import {
  millisecondsPerFrame,
} from '../../Utils';
import {
  defaultPanZoomButtonAnimationDuration as defaultControlButtonAnimationDuration,
  getRelativeCoord,
  isPanOutsideLimits,
  matrixToSVGTransform,
  mouseDeltaToZoomFactorScale,
} from '../../viz/zoomPanUtils';
import {
  IProcessedNode,
  minCircleRadius,
  NodeSizing,
} from '../../workerStore/feasibility/Utils';
import {
  xDomainWideningFactor,
  yDomainWideningFactor,
} from '../../workerStore/feasibility/Utils';
import {
  controlButtonZoomFactor,
  HideExports,
  maxZoomInIncrements,
  maxZoomOutIncrements,
  nodeRadiusZoomSplit,
  normalNodeActiveStrokeWidth,
  svgBackgroundColor,
  YAxisMeasure,
} from '../Utils';
import Node from './Node';
const styles = require('../../sharedComponents/zoomPanControls.css');
import line from 'd3-shape/src/line';
import {
  sendHeroElementTiming,
} from '../../heroElement';
import memoize from '../../memoizeWithJSON';
import {
  ChartRoot,
  offChartTextStyling,
  XAxisContainer,
  YAxisContainer,
} from '../Grid';
import GridLines from './GridLines';
import {
  assignHighlightStatus as unmemoizedAssignHighlightStatus,
} from './Utils';
const {
  heroElementNames,
} = require('../../../buildConstants');
import { ProductClass } from '../../graphQL/types/shared';
import {
  axisLabelStyle,
} from '../../sharedComponents/TextStyling';
import ProjectionLines from './ProjectionLines';
import Ticks from './Ticks';
import YAxisLabel from './YAxisLabel';

const zIndices = {
  background: 10,
  ticks: 20,
  coloredBars: 30,
  chartNormal: 40,
  projectionLinesNormal: 50,
  yAxisLabel: 60,
  elevated: 200,
};
const ChartBackground = styled(ChartRoot)`
  z-index: ${zIndices.background};
  position: relative;
`;

const XContainerExplore = styled(XAxisContainer)`
  ${axisLabelStyle}
  ${offChartTextStyling}
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const XContainerCountryPages = styled(XAxisContainer)`
  color: #666;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1rem;
  letter-spacing: 1px;
`;

const YLabelContainer = styled(YAxisContainer)`
  ${offChartTextStyling}
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: ${zIndices.yAxisLabel};
`;

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--active-stroke-width'?: number;
  }
}

interface IXY {
  x: number;
  y: number;
}
const getPolygonPath = line<IXY>().x(({x}) => x).y(({y}) => y);
const getClosedPath = (vertices: IXY[]) => {
  const path = getPolygonPath(vertices)!;
  return `${path}Z`;
};

// When a user zooms in, we don't want the nodes' apparent sizes to get larger
// by the same amount because the amount of overlapping between the nodes would
// stay the same, leaving the chart at the same level of clutter. Instead, we
// want the nodes' apparent sizes to grow larger at a slower rate rate that the
// zoom so that as the user zooms in, nodes that overlap can be separated out
// more easily. This is controled by `nodeRadiusZoomSplit`. It's a number
// between 0 and 1. If it's 1, the nodes will stay the same size regardless of
// zoom-in amount, as it gets smaller, the nodes will grow in size at faster
// rates. If it's 0, the nodes will grow by the same amount as the zoom factor.
const getRadiusAdjustmentFactor = (chartScaleFactor: number) => {
  if (chartScaleFactor <= 1) {
    return 1;
  } else {
    const reciprocal = 1 / chartScaleFactor;
    const departureFromOne = 1 - reciprocal;
    const amountToSubtractFromOne = departureFromOne * nodeRadiusZoomSplit;
    return 1 - amountToSubtractFromOne;
  }
};

// Limits for zoom factors for zooming in (max) and zooming out (min);
const maxZoomFactorLimit = controlButtonZoomFactor ** (maxZoomInIncrements + 1);
const minZoomFactorLimit = (1 / controlButtonZoomFactor) ** (maxZoomOutIncrements + 1);

const ChartContainer = styled(ChartRoot)`
  width: 100%;
  cursor: grab;
  overflow: hidden;
  outline: 1px solid white;
  position: relative;
  z-index: ${zIndices.chartNormal};
`;

// Need `relative` position so that the nodes will appear on top of the grid
// lines:
const SVG = styled.svg`
  position: relative;
`;

export enum Theme {
  Explore,
  CountryPages,
}

export interface Props {
  // Current zoom factor for the SVG canvas. Needed so that we can scale the
  // nodes down when the user zoom in to give more free space between the nodes:
  scaleFactor: number;

  hideExports: HideExports;

  nodes: IProcessedNode[];
  // Products the user selects with mouse click:
  selectedProducts: string[];
  // The single product currently selected in the "highlight dropdown":
  highlightedProduct: string | undefined;
  // The single product the user is currently hovering over:
  hoveredProduct: string | undefined;

  transformationMatrix: ITransformationMatrix;
  yAverage: number;

  // These are the upper/lower bounds that actually show up on the x and y axes:
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;

  year: number;
  yAxisMeasure: YAxisMeasure;
  nodeSizing: NodeSizing;

  updateTransformationMatrix: (matrix: ITransformationMatrix) => void;
  // Pass the root element of this component to the parent for DOM measurement
  // on window resizing:
  saveRootEl: (el: HTMLElement | null) => void;
  // DOM layout info:
  svgWidth: undefined | number;
  svgHeight: undefined | number;
  topOffset: undefined | number;
  leftOffset: undefined | number;

  onNodeMouseEnter: (id: string) => void;
  onNodeMouseLeave: (id: string) => void;
  onNodeClick: (id: string) => void;
  onDoubleClick: () => void;

  onYAxisOptionSelect: (measure: YAxisMeasure) => void;
  tooltipMap: Record<string, IProcessedNode>;

  // true in country pages where we need to highlight top products by fading out
  // the non-top ones:
  shouldNodeBeFadedByDefault: boolean;

  theme: Theme;
  productClass: ProductClass;
}

interface IState {
  areYAxisOptionsShown: boolean;
}

export default class extends React.PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      areYAxisOptionsShown: false,
    };
  }

  private reportHeroElementTimingIfNeeded(props: Props) {
    if (props.svgWidth !== undefined && props.svgHeight !== undefined) {
      sendHeroElementTiming(heroElementNames.feasibility);
    }
  }

  componentDidMount() {
    this.reportHeroElementTimingIfNeeded(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    const nextProps = this.props;
    if (nextProps !== prevProps) {
      this.reportHeroElementTimingIfNeeded(nextProps);
    }
  }

  private assignHighlightStatus = memoize(unmemoizedAssignHighlightStatus);

  private svgGroup: SVGElement | null = null;
  private rememberSVGGroup = (el: SVGElement | null) => {
    this.svgGroup = el;
  }
  private passRootElToParent = (el: HTMLElement | null) => this.props.saveRootEl(el);

  // Source of truth for `transformationMatrix`:
  private transformationMatrix: ITransformationMatrix = getIdentityTransformMatrix();

  /* Start of zoom-related methods */

  // Need to store mouse position outside the tween function because `throttle`d functions can't take
  // arguments:
  private mouseX: number = 0;
  private mouseY: number = 0;
  private _batchZooms = throttle(() => {
    requestAnimationFrame(() => {
      const svgGroup = this.svgGroup;
      if (svgGroup !== null) {
        const props = this.props;
        const {topOffset, leftOffset} = props;
        const delta = this.deltaAccumulator;
        this.deltaAccumulator = 0;
        const transformationMatrix = this.transformationMatrix;
        const {x, y} = getRelativeCoord(this.mouseX, this.mouseY, topOffset!, leftOffset!);
        const zoomFactor = mouseDeltaToZoomFactorScale(delta);

        const newMatrix = zoom(transformationMatrix, x, y, zoomFactor);
        const newScaleFactor = getScaleFactor(newMatrix);

        // Constrain zoom to within zoom limits:
        if (newScaleFactor <= maxZoomFactorLimit && newScaleFactor >= minZoomFactorLimit) {
          const newTransform = matrixToSVGTransform(newMatrix);
          svgGroup.setAttribute('transform', newTransform);
          this.transformationMatrix = newMatrix;
          props.updateTransformationMatrix(newMatrix);
        }
      }
    });
  // Run at most once per frame:
  }, millisecondsPerFrame);

  private deltaAccumulator: number = 0;
  private onWheelTemp = (event: React.WheelEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    const {deltaY, clientX, clientY} = event;
    // const timeStamp = Date.now();
    this.mouseX = clientX;
    this.mouseY = clientY;

    this.deltaAccumulator += deltaY;
    this._batchZooms();
  }
  /* End of zoom-related methods*/

  /* Start of pan-related methods: */
  private panStart: IPoint | undefined;
  private isPanning: boolean = false;
  private configAtStart: ITransformationMatrix | undefined;
  private configDuringPan: ITransformationMatrix | undefined;

  // These are variables are used to store the latest panning-related mouse event data
  // (because of React's event pooling).
  private panClientX: number | undefined;
  private panClientY: number | undefined;
  private panButtons: number | undefined;

  private onMouseDownPan = (event: React.MouseEvent<any>) => {
    const {clientX, clientY} = event;
    const {leftOffset, topOffset} = this.props;
    const {x, y} = getRelativeCoord(clientX, clientY, topOffset!, leftOffset!);
    this.panStart = {x, y};
    this.isPanning = true;
    const transformationMatrix = this.transformationMatrix;
    this.configDuringPan = transformationMatrix;
    this.configAtStart = transformationMatrix;
  }
  private _stopPan() {
    this.panStart = undefined;
    this.isPanning = false;
    this.configAtStart = undefined;

    // Note: `this.configDuringPan` can be `undefined` here if during a very
    // short pan, the pan is prevented from happening because of pan limit:
    if (this.configDuringPan !== undefined) {
      this.transformationMatrix = this.configDuringPan;
    }
    this.configDuringPan = undefined;
    this.panClientX = undefined;
    this.panClientY = undefined;
    this.panButtons = undefined;
  }
  private onMouseUpPan = () => {
    this._stopPan();
  }
  private onMouseMovePan = ({clientX, clientY, buttons}: React.MouseEvent<any>) => {
    if (this.isPanning) {
      this.panClientX = clientX;
      this.panClientY = clientY;
      this.panButtons = buttons;
      this._batchPans();
    }
  }
  private _batchPans = throttle(() => {
    requestAnimationFrame(() => {
      // Need to check `isPanning` because by the time this function is invoked (asynchronously),
      // the pan may already have stopped and relevant variables have already been reset
      // to`unefined`.
      const svgGroup = this.svgGroup;
      if (this.isPanning && svgGroup !== null) {
        const clientX = this.panClientX!;
        const clientY = this.panClientY!;
        const buttons = this.panButtons!;
        if (buttons === 0) {
          // Stop panning when mouse leaves the SVG area:
          this._stopPan();
        } else {
          const props = this.props;
          const {leftOffset, topOffset, svgHeight, svgWidth} = props;
          if (svgHeight !== undefined && svgWidth !== undefined) {
            const {x, y} = getRelativeCoord(clientX, clientY, topOffset!, leftOffset!);
            const newMatrix = updatePanning(this.configAtStart!, {x, y}, this.panStart!);
            const shouldNotPan = isPanOutsideLimits(
              newMatrix, {width: svgWidth, height: svgHeight},
              xDomainWideningFactor, yDomainWideningFactor,
            );
            // Constrain pan to within limits:
            if (!shouldNotPan) {
              svgGroup.setAttribute('transform', matrixToSVGTransform(newMatrix));
              this.configDuringPan = newMatrix;
              props.updateTransformationMatrix(newMatrix);
            } else {
              this._stopPan();
            }
          }
        }
      }
    });
  }, millisecondsPerFrame);

  /* End of pan-related methods: */

  /* Start of control buttons-related methods*/

  // We need to let `TweenLite` "touch" the SVG element before tweening because
  // othwerwise, greensock will have trouble reading the existing `transform`
  // attribute and won't be able to perform the tween:
  private prepForGreensock() {
    const transformationMatrix = this.transformationMatrix;
    const currentTransform = matrixToSVGTransform(transformationMatrix);
    TweenLite.set(this.svgGroup, {transform: currentTransform});
  }

  // Indicate whether a tween (initiated by the zoom/pan buttons) are in
  // progress.
  private isTweening: boolean = false;

  private _zoomInOut(zoomFactor: number) {
    const props = this.props;
    const {svgWidth, svgHeight} = props;
    if (svgWidth !== undefined && svgHeight !== undefined && this.isTweening !== true) {
      this.isTweening = true;
      this.prepForGreensock();
      const transformationMatrix = this.transformationMatrix;
      const newMatrix = zoom(
        transformationMatrix, svgWidth / 2, svgHeight / 2, zoomFactor,
      );
      const newScaleFactor = getScaleFactor(newMatrix);
      if (newScaleFactor <= maxZoomFactorLimit && newScaleFactor >= minZoomFactorLimit) {
        const newTransform = matrixToSVGTransform(newMatrix);
        TweenLite.to(this.svgGroup, defaultControlButtonAnimationDuration, {
          transform: newTransform,
          onComplete: () => {
            props.updateTransformationMatrix(newMatrix);
            this.transformationMatrix = newMatrix;
            this.isTweening = false;
          },
        });
      } else {
        this.isTweening = false;
      }
    }
  }
  private zoomIn = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this._zoomInOut(controlButtonZoomFactor);
  }
  private zoomOut = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this._zoomInOut(1 / controlButtonZoomFactor);
  }
  private resetTransformations = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const props = this.props;
    this.isTweening = true;
    this.prepForGreensock();
    const newMatrix = getIdentityTransformMatrix();
    const newTransform = matrixToSVGTransform(newMatrix);
    TweenLite.to(this.svgGroup, defaultControlButtonAnimationDuration, {
      transform: newTransform,
      onComplete: () => {
        props.updateTransformationMatrix(newMatrix);
        this.transformationMatrix = newMatrix;
        this.isTweening = false;
      },
    });
  }

  private swallowDoubleClick = (e: React.MouseEvent<any>) => e.stopPropagation();

  private onYAxisOptionSelect = (measure: YAxisMeasure) => this.setState(
    (prevState: IState): IState => ({...prevState, areYAxisOptionsShown: false}),
    () => this.props.onYAxisOptionSelect(measure),
  )

  /* End of control buttons-related methods*/
  render() {
    const props = this.props;
    const {svgWidth, svgHeight, scaleFactor} = props;

    if (svgWidth === undefined || svgHeight === undefined) {
      return (
        <ChartContainer ref={this.passRootElToParent}/>
      );
    } else {
      const {
        nodes, hideExports, selectedProducts, highlightedProduct,
        onNodeMouseEnter, onNodeMouseLeave, onNodeClick, onDoubleClick,
        transformationMatrix, yAverage, year, yAxisMeasure,
        xAxisMin, xAxisMax, yAxisMin, yAxisMax,
        nodeSizing, tooltipMap, hoveredProduct, shouldNodeBeFadedByDefault,
        theme, productClass,
      } = props;

      const {areYAxisOptionsShown} = this.state;

      //#region Chart elements
      const scaledActiveNodeStrokeWidth = normalNodeActiveStrokeWidth / scaleFactor;

      const nodesWithHighlight = this.assignHighlightStatus(nodes, selectedProducts, highlightedProduct);

      const radiusAdjustmentFactor = getRadiusAdjustmentFactor(scaleFactor);

      const nodeElems = nodesWithHighlight.map(
        ({x, y, color, radius, id, exportStatus, isHighlighted, active, isTopProduct}) => {
        const unscaledRadius = (nodeSizing === NodeSizing.WorldTrade) ? radius : minCircleRadius;
        const scaledRadius =  unscaledRadius * radiusAdjustmentFactor;
        return (
          <Node
            radius={scaledRadius} x={x} y={y} color={color}
            hideExports={hideExports} exportStatus={exportStatus}
            isHighlighted={isHighlighted} active={active}
            isTopProduct={isTopProduct}
            id={id} onMouseEnter={onNodeMouseEnter} onMouseLeave={onNodeMouseLeave} onNodeClick={onNodeClick}
            shouldNodeBeFadedByDefault={shouldNodeBeFadedByDefault}
            key={id}
          />
        );
      });

      const svgStyle = {
        '--active-stroke-width': scaledActiveNodeStrokeWidth,
      };

      const xSubtraction = svgWidth * (controlButtonZoomFactor ** maxZoomOutIncrements - 1);
      const ySubtraction = svgHeight * (controlButtonZoomFactor ** maxZoomOutIncrements - 1);
      const halfWidth = svgWidth / 2;
      const halfHeight = svgHeight / 2;

      const lightShadedPath = getClosedPath([
        {x: - xSubtraction, y: -halfHeight},
        {x: halfWidth, y: - ySubtraction},
        {x: halfWidth, y: halfHeight},
        {x: -xSubtraction, y: halfHeight},
      ]);
      //#endregion

      //#region Axis elements
      const xLabel = __lexiconText('axisName.distance');

      let shouldElevateYAxisOptions: boolean, shouldElevateProjectionMarkers: boolean;
      if (hoveredProduct !== undefined) {
        shouldElevateProjectionMarkers = true;
        shouldElevateYAxisOptions = false;
      } else if (areYAxisOptionsShown === true) {
        shouldElevateProjectionMarkers = false;
        shouldElevateYAxisOptions = true;
      } else {
        shouldElevateProjectionMarkers = false;
        shouldElevateYAxisOptions = false;
      }

      const yLabel = (
        <YAxisLabel
          yAxisMeasure={yAxisMeasure}
          onOptionSelect={this.onYAxisOptionSelect}
          theme={theme}
        />
      );

      const ticks = (
        <Ticks
          transformationMatrix={transformationMatrix}
          svgWidth={svgWidth} svgHeight={svgHeight}
          xAxisMin={xAxisMin} xAxisMax={xAxisMax}
          yAxisMin={yAxisMin} yAxisMax={yAxisMax}
          zIndex={zIndices.ticks} theme={theme}
        />
      );

      const projectionLineZIndex = shouldElevateProjectionMarkers ? zIndices.elevated : zIndices.projectionLinesNormal;
      const projectionLines = (
        <ProjectionLines
          transformationMatrix={transformationMatrix}
          svgWidth={svgWidth} svgHeight={svgHeight}
          tooltipMap={tooltipMap} hoveredProduct={hoveredProduct}
          zIndex={projectionLineZIndex}
        />
      );
      //#endregion

      const yAxisLabelContainerStyle: React.CSSProperties = {
        zIndex: shouldElevateYAxisOptions ? zIndices.elevated : undefined,
      };

      const chartBackgroundColor: React.CSSProperties = {
        backgroundColor: theme === Theme.Explore ? svgBackgroundColor : 'white',
      };
      const shadedPath = theme === Theme.Explore
        ? <path d={lightShadedPath} fill='rgb(223, 227, 232)' fillOpacity={0.5}/>
        : null;

      const XContainer = theme === Theme.Explore ? XContainerExplore : XContainerCountryPages;

      return (
        <>
          <ChartBackground style={chartBackgroundColor} />
          <ChartContainer
            ref={this.passRootElToParent}
            onWheel={this.onWheelTemp}
            onDoubleClick={onDoubleClick}>

            <GridLines
              transformationMatrix={transformationMatrix}
              yAverage={yAverage}
              xAxisMin={xAxisMin} xAxisMax={xAxisMax}
              yAxisMin={yAxisMin} yAxisMax={yAxisMax}
              year={year} yAxisMeasure={yAxisMeasure}
              svgWidth={svgWidth} svgHeight={svgHeight}
              theme={theme}
              productClass={productClass}
            />

            <SVG width={svgWidth} height={svgHeight}
              onMouseDown={this.onMouseDownPan}
              onMouseUp={this.onMouseUpPan}
              onMouseMove={this.onMouseMovePan}
              style={svgStyle}>

              <g ref={this.rememberSVGGroup}>
                {shadedPath}
                {nodeElems}
              </g>
            </SVG>
            <div className={styles.container}>
              <button onClick={this.zoomIn} onDoubleClick={this.swallowDoubleClick}>+ ZOOM</button>
              <button onClick={this.zoomOut} onDoubleClick={this.swallowDoubleClick}>- ZOOM</button>
              <button onClick={this.resetTransformations} onDoubleClick={this.swallowDoubleClick}>RESET ZOOM</button>
            </div>
          </ChartContainer>
          {ticks}
          {projectionLines}
          <XContainer>{xLabel}</XContainer>
          <YLabelContainer style={yAxisLabelContainerStyle}>
            {yLabel}
          </YLabelContainer>
        </>
      );
    }
  }
}
