import React from 'react';
import {
  Axis,
} from './Utils';

interface IProps {
  coord: number;
  color: string;
  axis: Axis;
  className: string;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {coord, color, className} = this.props;
    const style = {
      '--translation': `${coord}px`,
      'backgroundColor': color,
    };
    return (
      <div className={className} style={style}/>
    );
  }
}
