import React from 'react';
import {
  ExportStatus,
} from '../../workerStore/feasibility/Utils';
import {
  HideExports,
  nodeHoverDelayDuration as hoverDelayDuration,
} from '../Utils';
import {
  getNodeConstituents,
} from './Utils';
const styles = require('./node.css');
import {
  Subscription,
} from 'rxjs';
import {
  getTooltipVisibilityStream,
} from '../../sharedComponents/UITooltip';

interface IProps {
  id: string;
  x: number;
  y: number;
  exportStatus: ExportStatus;
  color: string;
  radius: number;
  hideExports: HideExports;
  active: boolean;
  // true in country pages where we need to highlight top products by fading out
  // the non-top ones:
  shouldNodeBeFadedByDefault: boolean;

  isHighlighted: boolean;
  isTopProduct: boolean;
  onMouseEnter: (id: string) => void;
  onMouseLeave: (id: string) => void;
  onNodeClick: (id: string) => void;
}

export default class extends React.PureComponent<IProps, {}> {
  private el: SVGElement | null = null;
  private rememberEl = (el: SVGElement | null) => this.el = el;
  private showOrHideSubscription: Subscription | undefined;

  componentDidMount() {
    const {el} = this;
    if (el !== null) {
      const showOrHide$ = getTooltipVisibilityStream(el, hoverDelayDuration);
      this.showOrHideSubscription = showOrHide$.subscribe(shouldShow => {
        const {onMouseEnter, id, onMouseLeave} = this.props;
        if (shouldShow === true) {
          onMouseEnter(id);
        } else {
          onMouseLeave(id);
        }
      });
    }
  }

  componentWillUnmount() {
    const {showOrHideSubscription} = this;
    if (showOrHideSubscription !== undefined) {
      showOrHideSubscription.unsubscribe();
    }
  }

  private onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const {id, onNodeClick} = this.props;
    onNodeClick(id);

  }
  private onDoubleClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  }
  render() {
    const {
      x, y, color, radius, hideExports, exportStatus,
      isHighlighted, active, isTopProduct, shouldNodeBeFadedByDefault,
    } = this.props;

    const {circle, pieArc, interactiveOutline} = getNodeConstituents({
      x, y, exportStatus, color, radius, hideExports, active,
      isDynamic: true,
      nodeClassName: `${styles.node} ${styles.interactive}`,
      highlightedClassName: styles.highlighted,
      topProductClassName: styles.topProduct,
      interactiveOutlineClassName: styles.interactiveOutline,
      fadedOutClassName: styles.fadedDefaultLook,
      isHighlighted,
      isTopProduct,
      shouldNodeBeFadedByDefault,
    });

    // TODO: revise where mouse event handlers are attached:
    return (
      <g onClick={this.onClick}
        ref={this.rememberEl} onDoubleClick={this.onDoubleClick}>
        {circle}
        {pieArc}
        {interactiveOutline}
      </g>
    );
  }
}
