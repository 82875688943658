import React from 'react';
import DetailOverlay from '../../sharedComponents/DetailOverlay';
import {
  IProcessedNode,
} from '../../workerStore/feasibility/Utils';

interface Props {
  tooltipMap: Record<string, IProcessedNode>;
  detailedProduct: string;
  year: number;
  hideOverlay: () => void;
}

const DetailOverlayContainer = (props: Props) => {
  const {
    tooltipMap, detailedProduct, hideOverlay,
  } = props;
  const retrievedDetailProduct = tooltipMap[detailedProduct];

  let detailOverlay: React.ReactElement<any> | null;
  if (retrievedDetailProduct === undefined) {
    detailOverlay = null;
  } else {
    const {
      color, longLabel, detailOverlayInfo,
    } = retrievedDetailProduct;
    detailOverlay = (
      <DetailOverlay key='detail-overlay'
        rows={detailOverlayInfo}
        color={color}
        title={longLabel}
        hideOverlay={hideOverlay}/>
    );
  }
  return detailOverlay;
};

export default DetailOverlayContainer;
