import React from 'react';
import styled from 'styled-components';
import { ProductClass } from '../../graphQL/types/shared';
import memoize from '../../memoize';
import {
  ITransformationMatrix,
} from '../../network/panZoom';
import {
  getAverageLineLabel,
} from '../container/Utils';
import {
  YAxisMeasure,
} from '../Utils';
import {
  getTicksInfoGetter,
} from './getTicksInfo';
import {
  Theme,
} from './index';

const sharedStyles = require('../container/axisElement.css');
const gridlineStyles = require('./gridline.css');
const styles = require('./ticks.css');

const HorizontalLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
`;

const VerticalLine = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  width: 1px;
`;

const LineSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
`;

interface IProps {
  transformationMatrix: ITransformationMatrix;
  svgWidth: number;
  svgHeight: number;
  yAverage: number | null;
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;
  year: number;
  yAxisMeasure: YAxisMeasure;
  theme: Theme;
  productClass: ProductClass;
}

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--translation'?: string;
  }
}

export default class extends React.Component<IProps> {
  private getTicksInfo = memoize(getTicksInfoGetter());

  render() {
    const {
      svgWidth, svgHeight, transformationMatrix,
      yAxisMin, yAxisMax, xAxisMin, xAxisMax, yAverage,
      year, yAxisMeasure, theme,
    } = this.props;

    const {
      xTickValues,
      yTickValues,
      transformedXScale,
      transformedYScale,
    } = this.getTicksInfo({
      svgWidth, svgHeight,
      xAxisMin, xAxisMax,
      yAxisMin, yAxisMax,
      transformationMatrix,
    });

    const verticalGridlineClassName = `${sharedStyles.element} ${gridlineStyles.gridline} ${gridlineStyles.y}`;
    const horizontalGridlineClassName = `${sharedStyles.element} ${gridlineStyles.gridline} ${gridlineStyles.x}`;

    const verticalGridLines = yTickValues.map(value => {
      if (theme === Theme.Explore) {
        const style: React.CSSProperties = {
          '--translation': `${transformedYScale(value)}px`,
        };
        return (
          <div key={`vert-gridline-${value}`} style={style} className={verticalGridlineClassName}/>
        );
      } else {
        return (
          <HorizontalLine
            style={{top: `${transformedYScale(value)}px`}}
            key={`grid-line-${value}`}
          >
            <LineSVG>
              <line
                x1={0} y1={0}
                x2={'100%'} y2={0}
                stroke='black' strokeOpacity={0.6} strokeDasharray={2}
                key={`grid-line-y-${value}`}
              />
            </LineSVG>
          </HorizontalLine>
        );
      }
    });
    const horizontalGridLines = xTickValues.map(value => {
      if (theme === Theme.Explore) {
        const style = {
          '--translation': `${transformedXScale(value)}px`,
        };
        return (
          <div key={`horiz-gridline-${value}`} style={style} className={horizontalGridlineClassName}/>
        );
      } else {
        return (
          <VerticalLine
            style={{left: `${transformedXScale(value)}px`}}
            key={`grid-line-${value}`}
          >
            <LineSVG>
              <line
                x1={0} y1={0}
                x2={0} y2={'100%'}
                stroke='black' strokeOpacity={0.6} strokeDasharray={2}
                key={`grid-line-x-${value}`}
              />
            </LineSVG>
          </VerticalLine>
        );
      }
    });

    let averageLine: JSX.Element | null, averageLabel: JSX.Element | null;
    if (yAverage === null) {
      averageLine = null;
      averageLabel = null;
    } else {
      const averageYCoord = transformedYScale(yAverage);
      if (averageYCoord < 0 || averageYCoord > svgHeight || yAxisMeasure === YAxisMeasure.OpportunityGain) {
        averageLine = null;
        averageLabel = null;
      } else {

        const averageLineThemeClass = theme === Theme.Explore
          ? styles.averageLineExplore : styles.averageLineCountryPages;
        const averageLineClassName =
          `${styles.yPosition} ${styles.yTick} ${styles.averageLine} ${averageLineThemeClass}`;

        const averageStyle = {
          '--translation': `${averageYCoord}px`,
        };
        averageLine = (
          <div className={averageLineClassName} style={averageStyle}/>
        );

        const averageLabelThemeClass = theme === Theme.Explore ? '' : styles.averageLabelCountryPages;
        const averageLabelClassName = `${styles.yPosition} ${styles.averageLabel} ${averageLabelThemeClass}`;
        averageLabel = (
          <div className={averageLabelClassName} style={averageStyle}>
            {getAverageLineLabel(yAverage, year, this.props.productClass)}
          </div>
        );

      }
    }
    return (
      <>
        {verticalGridLines}
        {horizontalGridLines}
        {averageLine}
        {averageLabel}
      </>
    );
  }
}
