import React from 'react';

interface IProps {
  coord: number;
  label: string;
  tickClassName: string;
  tickLabelClassName: string;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {coord, label, tickClassName, tickLabelClassName} = this.props;

    const style = {
      '--translation': `${coord}px`,
    };

    return (
      <>
        <div
          className={tickClassName}
          style={style}/>
        <div
          className={tickLabelClassName}
          style={style}>{label}</div>

      </>
    );
  }
}
