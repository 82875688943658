import React from 'react';
import {
  IChoice,
} from '../../sharedComponents/radioSelector/Utils';
import {
  YAxisLabelContainer,
} from '../../stack/chart/YAxisLabelUtils';
import {
  YAxisMeasure,
} from '../Utils';
import YAxisOption from './AdjustmentOption';
import { Theme } from './index';

const yAxisMeasureChoices: Array<IChoice<YAxisMeasure>> = [{
  value: YAxisMeasure.Complexity,
  label: __lexiconText('yAxisMeasureSelector.complexity'),
}, {
  value: YAxisMeasure.OpportunityGain,
  label: __lexiconText('yAxisMeasureSelector.opportunityGain'),
}];

interface IProps {
  yAxisMeasure: YAxisMeasure;
  onOptionSelect: (adjustments: YAxisMeasure) => void;
  theme: Theme;
}

export default class extends React.Component<IProps> {
  private onOptionSelect = (measure: YAxisMeasure) => this.props.onOptionSelect(measure);

  render() {
    const {
      yAxisMeasure,
      theme,
    } = this.props;
    const yAxisContent = yAxisMeasureChoices.map(({value, label}) => (
      <YAxisOption<YAxisMeasure>
        key={value}
        selected={yAxisMeasure}
        assigned={value}
        label={label}
        onClick={this.onOptionSelect}
        theme={theme}
      />
    ));

    return (
      <YAxisLabelContainer>
        {yAxisContent}
      </YAxisLabelContainer>

    );

  }
}
