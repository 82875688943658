import React from 'react';
import styled from 'styled-components';
import {
  applyMatrixToPoint,
  ITransformationMatrix,
} from '../../network/panZoom';
import {
  formatDecimal,
} from '../../viz/Utils';
import {
  IProcessedNode,
} from '../../workerStore/feasibility/Utils';
import {
  ChartRoot,
  XAxisContainer as XAxisContainerBase,
  YAxisContainer as YAxisContainerBase,
} from '../Grid';
const styles = require('./projectionLine.css');

const XAxisContainer = styled(XAxisContainerBase)`
  pointer-events: none;
`;
const YAxisContainer = styled(YAxisContainerBase)`
  pointer-events: none;
`;
const TooltipsContainer = styled(ChartRoot)`
  position: relative;
  pointer-events: none;
`;
interface IProps {
  tooltipMap: Record<string, IProcessedNode>;
  hoveredProduct: string | undefined;
  svgWidth: number | undefined;
  svgHeight: number | undefined;
  transformationMatrix: ITransformationMatrix;
  zIndex: number;
}

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--marker-color'?: string;
    '--scale-factor'?: number;
  }
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {
      tooltipMap, hoveredProduct,
      svgHeight, svgWidth, transformationMatrix,
      zIndex,
    } = this.props;

    if (svgHeight === undefined || svgWidth === undefined) {
      return null;
    } else {
      let yProjectionLine: JSX.Element | null, xProjectionLine: JSX.Element | null;
      let xMarker: JSX.Element | null, yMarker: JSX.Element | null;
      if (hoveredProduct === undefined) {
        yProjectionLine = null;
        xProjectionLine = null;
        xMarker = null;
        yMarker = null;
      } else {
        const retrievedTooltipDatum = tooltipMap[hoveredProduct];
        if (retrievedTooltipDatum === undefined) {
          throw new Error('Cannot find tooltip data for product' + hoveredProduct);
        }
        const {x, y, xValue, yValue, color} = retrievedTooltipDatum;
        const {x: transformedX, y: transformedY} = applyMatrixToPoint(transformationMatrix, x, y);
        const colorStyle = {
          '--marker-color': color,
        };
        const yProjectionLineStyle: React.CSSProperties = {
          ...colorStyle,
          '--scale-factor': transformedX / svgWidth,
          '--translation': `${transformedY}px`,
        };
        const xProjectionLineStyle: React.CSSProperties = {
          ...colorStyle,
          // Because we want to project down to the x axis but the
          // `transform-origin` of the line is at the bottom:
          '--scale-factor': 1 - transformedY / svgHeight,

          '--translation': `${transformedX}px`,
        };

        const yProjectionLineClassName = `${styles.projection} ${styles.projectionLine} ${styles.projectionLineY}`;
        const xProjectionLineClassName = `${styles.projection} ${styles.projectionLine} ${styles.projectionLineX}`;
        const xMarkerClassName = `${styles.projection} ${styles.marker} ${styles.markerX}`;
        const yMarkerClassName = `${styles.projection} ${styles.marker} ${styles.markerY}`;

        yProjectionLine = (
          <div className={yProjectionLineClassName} style={yProjectionLineStyle}/>
        );
        xProjectionLine = (
          <div className={xProjectionLineClassName} style={xProjectionLineStyle}/>
        );
        const xMarkerStyle: React.CSSProperties = {
          ...colorStyle,
          '--translation': `${transformedX}px`,
        };
        const yMarkerStyle: React.CSSProperties = {
          ...colorStyle,
          '--translation': `${transformedY}px`,
        };
        xMarker = (
          <div className={xMarkerClassName} style={xMarkerStyle}>{formatDecimal(xValue)}</div>
        );
        yMarker = (
          <div className={yMarkerClassName} style={yMarkerStyle}>{formatDecimal(yValue)}</div>
        );
      }

      const style: React.CSSProperties = {
        zIndex,
      };

      return (
        <>
          <XAxisContainer style={style}>
            {xMarker}
          </XAxisContainer>
          <YAxisContainer style={style}>
            {yMarker}
          </YAxisContainer>
          <TooltipsContainer style={style}>
            {xProjectionLine}
            {yProjectionLine}
          </TooltipsContainer>
        </>
      );

    }

  }

}
