import classnames from 'classnames';
import {
  darken,
} from 'polished';
import React from 'react';
import {
  ExportStatus,
  ExportType,
  IProcessedNode,
} from '../../workerStore/feasibility/Utils';
import {
  HideExports,
  inactiveNodeBackgroundColor,
  inactiveNodeStrokeColor,
  strokeColor,
} from '../Utils';
/* Start of pie arc `path` generator: */
// Adapted from https://stackoverflow.com/a/18473154/7075699
const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians)),
  };
};

export const getArcPath = (x: number, y: number, radius: number, percentage: number) => {
  const endAngle = percentage * 360;
  const start = polarToCartesian(x, y, radius, endAngle);
  const arcEndpoint = {x, y: y - radius};

  const largeArcFlag = endAngle <= 180 ? '0' : '1';

  const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, arcEndpoint.x, arcEndpoint.y,
      'L', x, y,
  ].join(' ');

  return d;
};
/* End of pie arc `path` generator: */

export interface IDisplayedNode extends IProcessedNode {
  isHighlighted: boolean;
}

export const assignHighlightStatus = (
  nodes: IProcessedNode[],
  selectedProducts: string[],
  highlightedProduct: string | undefined): IDisplayedNode[] => {

  const pairsWithoutHighlighted = selectedProducts.map(
    id => ([id, true] as [string, boolean]),
  );

  const selectedProductsPairs = (highlightedProduct === undefined) ?
                                pairsWithoutHighlighted :
                                [...pairsWithoutHighlighted, [highlightedProduct, true]] as Array<[string, boolean]>;

  const selectedProductsMap = new Map(selectedProductsPairs);
  return nodes.map(node => {
    const retrieved = selectedProductsMap.get(node.id);
    return {
      ...node,
      isHighlighted: retrieved === true,
    };
  });
};

type IDisjointInput = {
  isDynamic: true;
  nodeClassName: string;
  highlightedClassName: string;
  topProductClassName: string
  interactiveOutlineClassName: string;
  isHighlighted: boolean;
  isTopProduct: boolean
  // true in country pages where we need to highlight top products by fading out
  // the non-top ones:
  shouldNodeBeFadedByDefault: boolean
  fadedOutClassName: string
} | {
  isDynamic: false,
};

type IInput = IDisjointInput & {
  x: number;
  y: number;
  exportStatus: ExportStatus;
  color: string;
  radius: number;
  hideExports: HideExports;
  active: boolean;
};

interface IOutput {
  circle: JSX.Element;
  pieArc: JSX.Element | null;
  interactiveOutline: JSX.Element | null;
}
export const getNodeConstituents = (input: IInput): IOutput => {
  const {
    x, y, exportStatus, color, radius, hideExports, active,
  } = input;
  const circleProps = {
    cx: x,
    cy: y,
    r: radius,
  };

  const colorForNonExports = active ? color : inactiveNodeBackgroundColor;
  const colorForExports = darken(0.1, colorForNonExports);
  const opacityForNonExports = active ? 0.65 : 0.3;
  const opacityForExports = 0.95;
  const strokeForNonExports = active ? strokeColor : inactiveNodeStrokeColor;
  const strokeForExports = '#000';
  const strokeWidthForNonExports = 1;
  const strokeWidthForExports = 1.6;

  let nodeClassName: string, interactiveOutlineClassName: string;
  let interactiveOutlineProps: React.SVGProps<SVGCircleElement>;
  if (input.isDynamic === true) {
    nodeClassName = classnames(input.nodeClassName, {
      [input.highlightedClassName]: input.isHighlighted,
      [input.topProductClassName]: input.isTopProduct,
      [input.fadedOutClassName]: input.shouldNodeBeFadedByDefault,
    });
    interactiveOutlineClassName = `${nodeClassName} ${input.interactiveOutlineClassName}`;
    interactiveOutlineProps = {};
  } else {
    nodeClassName = '';
    interactiveOutlineClassName = '';
    interactiveOutlineProps = {
      fillOpacity: 0,
    };
  }

  const vectorEffect = (input.isDynamic === true) ? 'non-scaling-stroke' : 'none';

  let circleFill: string, circleOpacity: number, circleStroke: string, strokeWidth: number, circleClassName: string;
  if (exportStatus.type === ExportType.WhollyExported && hideExports === HideExports.Off) {
    circleFill = colorForExports;
    circleOpacity = opacityForExports;
    circleStroke = strokeForExports;
    circleClassName = nodeClassName;
    strokeWidth = strokeWidthForExports;
  } else if (exportStatus.type === ExportType.Mixed && hideExports === HideExports.Off) {
    circleFill = colorForNonExports;
    circleOpacity = opacityForNonExports;
    circleStroke = strokeForNonExports;
    circleClassName = nodeClassName;
    strokeWidth = strokeWidthForNonExports;
  } else {
    circleFill = colorForNonExports;
    circleOpacity = opacityForNonExports;
    circleStroke = strokeForNonExports;
    circleClassName = nodeClassName;
    strokeWidth = strokeWidthForNonExports;
  }

  let pieArc: JSX.Element | null, interactiveOutline: JSX.Element | null;
  if (exportStatus.type === ExportType.Mixed && hideExports === HideExports.Off) {
    const percentage = exportStatus.exportedValue / exportStatus.totalValue;
    const pieArcPath = getArcPath(x, y, radius, percentage);
    pieArc = (
      <path d={pieArcPath} fill={colorForExports} vectorEffect={vectorEffect}/>
    );
    interactiveOutline = (
      <circle className={interactiveOutlineClassName} vectorEffect={vectorEffect}
        {...circleProps} {...interactiveOutlineProps}/>
    );
  } else {
    pieArc = null;
    interactiveOutline = null;
  }

  const circle = (
    <circle {...circleProps}
    className={circleClassName}
    fill={circleFill}
    fillOpacity={circleOpacity}
    stroke={circleStroke}
    strokeWidth={strokeWidth}
    vectorEffect={vectorEffect} />
  );

  return {
    circle, pieArc, interactiveOutline,
  };
};
