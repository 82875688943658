import React from 'react';
import styled from 'styled-components';
import { smallMediaQuery } from '../../countryProfiles/Grid';
import { primaryColor1, secondaryColor2, secondaryColor3 } from '../../countryProfiles/Utils';
import { primaryGray, secondaryGray } from '../../cssVariables';
import { selectedTextColor } from '../../viz/controls/Utils';
import { Theme } from './index';

const borderRadius = 0.625; // in rem

const Base = styled.div`
  display: inline-block;
  border: 1px solid ${secondaryColor3};
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.8rem 0.35rem;
  position: relative;
  cursor: pointer;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;

  &:first-child {
    border-top-right-radius: ${borderRadius}rem;
    border-top-left-radius: ${borderRadius}rem;
    border-bottom: none;
  }

  &:last-child {
    border-bottom-right-radius: ${borderRadius}rem;
    border-bottom-left-radius: ${borderRadius}rem;
    border-top: none;
  }

  @media ${smallMediaQuery} {
    font-size: 0.6rem;
    padding: 0.35rem;
  }
`;

const UnselectedExplore = styled(Base)`
  &:hover {
    color: ${selectedTextColor};
    background-color: ${secondaryGray};
  }
`;

const SelectedExplore = styled(UnselectedExplore)`
  font-weight: 700;
  color: ${selectedTextColor};
  background-color: ${primaryGray};

  &:hover {
    background-color: ${primaryGray};
    cursor: default;
  }
`;

const UnselectedCountryPages = styled(Base)`
  color: ${primaryColor1};

  &:hover {
    background-color: ${secondaryColor2};
  }
`;

const SelectedCountryPages = styled(Base)`
  background-color: ${primaryColor1};
  color: white;
  border-color: ${primaryColor1};
`;

interface IProps<T> {
  selected: T;
  assigned: T;
  label: string;
  onClick: (value: T) => void;
  theme: Theme;
}

export default class<T> extends React.Component<IProps<T>> {
  private onClick = () => this.props.onClick(this.props.assigned);

  render() {
    const {label, selected, assigned, theme} = this.props;

    if (theme === Theme.Explore) {
      const Root = (selected === assigned) ? SelectedExplore : UnselectedExplore;

      return (
        <Root onClick={this.onClick}>{label}</Root>
      );
    } else {
      const Root = (selected === assigned) ? SelectedCountryPages : UnselectedCountryPages;

      return (
        <Root onClick={this.onClick}>{label}</Root>
      );
    }
  }
}
