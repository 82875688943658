
export const svgBackgroundColor = 'rgb(245,245,245)';

export const inactiveNodeBackgroundColor = 'rgb(230, 230, 240)';
export const inactiveNodeStrokeColor = 'rgba(120, 120, 120, 0.2)';
export const strokeColor = 'rgb(70, 70, 90)';

export const inactiveNodeColor = '';

// Delay for responding to hover over node:
export const nodeHoverDelayDuration = 100; // in ms

// Unscaled stroke width for active/selected node:
export const normalNodeActiveStrokeWidth = 3;

// Only allow zooming 20 "clicks" of zooming in and 3 "clicks" of zooming out:
export const maxZoomInIncrements = 20;
export const maxZoomOutIncrements = 3;

// Zoom factor for one "click" of zooming in:
export const controlButtonZoomFactor = 1.1;

export const nodeRadiusZoomSplit = 1 / 1.8;

// Reserved space on the left and bottom of chart for axes/ticks:
export const leftMargin = 70; // in pixels
export const bottomMargin = 70; // in pixels

// See explanation at usage site
export const chartZoomPanDebounceInterval = 500; // in ms

export enum YAxisMeasure {
  Complexity,
  OpportunityGain,
}

export enum HideExports {
  Off,
  On,
}

export enum ErrorCode {
  NoData,
  PickCountry,
}

interface ISize {
  width: number;
  height: number;
  top: number;
  left: number;
}
export const chartContainerMeasureToSVGMeasure = ({width, height, top, left}: ISize) => ({
  width: width - leftMargin,
  height: height - bottomMargin,
  top,
  left: left + leftMargin,
});
