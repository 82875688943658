import styled, {
  css,
} from 'styled-components';
import {
  ChartContainer as ChartContainerBase,
} from '../viz/VizGrid';
import {
  vizGridZIndices,
} from '../viz/VizGrid';
import {
  bottomMargin,
  leftMargin,
} from './Utils';

export const gridRootCSS = css`
  display: grid;
  grid-template-rows: 1fr ${bottomMargin}px;
  grid-template-columns: ${leftMargin}px 1fr;
`;

export const ExplorePageFeasibilityChartContainer = styled(ChartContainerBase)`
  ${gridRootCSS}
`;

export const chartAreaCSS = css`
  grid-row: 1;
  grid-column: 2;
`;
export const ChartRoot = styled.div`
  ${chartAreaCSS}
`;

export const YAxisContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1;
`;
export const NodeSizingDisclaimerContainer = styled.div`
  position: relative;
  grid-row: 2;
  grid-column: 1 / 3;
`;

export const XAxisContainer = styled.div`
  position: relative;
  grid-row: 2;
  grid-column: 2;
`;

export const ExplorePageNonInteractiveChartContainer = styled(ExplorePageFeasibilityChartContainer)`
  pointer-events: none;
`;
export const ExplorePageTooltipsContainer = styled(ExplorePageFeasibilityChartContainer)`
  pointer-events: none;
  z-index: ${vizGridZIndices.tooltipsContainer};
`;

export const offChartTextStyling = css`
  font-size: 0.7rem;
  color: #666;
`;
