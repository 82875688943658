import debounce from 'lodash-es/debounce';
import identity from 'lodash-es/identity';
import React, {
  lazy,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import {
  MapDispatchToProps,
} from 'react-redux';
import {
  connect,
  MapStateToProps,
} from 'react-redux';
import Select from 'react-select';
import {
  AnyAction,
  Dispatch,
} from 'redux';
import styled from 'styled-components';
import {
  postMessageToMergeWorker,
} from '../../getConfiguredStore';
import {
  DataFacet,
  ProductClass,
  QueryLevel,
} from '../../graphQL/types/shared';
import {
  getIdentityTransformMatrix,
  getScaleFactor,
  ITransformationMatrix,
} from '../../network/panZoom';
import {categoryMapHS, categoryMapSITC, getHiddenCategories} from '../../network/Utils';
import {
  showDataNotes,
  showExports,
  showShare,
} from '../../overlay/actions';
import resizeMeasurementListener, {
  IListener,
} from '../../ResizeMeasurementListener';
import {
  IRootState,
} from '../../rootState';
import ComplexityGraphTitle from '../../sharedComponents/ComplexityGraphTitle';
import {
  DataIssueType,
} from '../../sharedComponents/dataNotes/Utils';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import Share, {
  DataNotesProps,
  TutorialModalProps,
} from '../../sharedComponents/newGraphShare';
import TriggerDataDownload from '../../sharedComponents/newGraphShare/TriggerDataDownload';
import HighlightDropdown from '../../sharedComponents/NewHighlightDropdown';
import RadioSelector from '../../sharedComponents/radioSelector';
import {
  IChoice,
} from '../../sharedComponents/radioSelector/Utils';
import Timeline, {
  TimelineType,
} from '../../sharedComponents/timeline';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  getDataSelector as getMainThreadCountryMetadataSelector,
} from '../../sharedData/newCountryMetadata';
import GraphTotal from '../../tree/graphTotal/GraphTotal';
import {
  ILoadable,
  INewDropdownOption,
  LoadableStatus,
  ProductMetadatumLevel as Level,
} from '../../Utils';
import ChartShownTitle from '../../viz/ChartShownTitle';
import determineNewGraphStatus from '../../viz/determineNewGraphStatus';
import {
  RightUpperControlContainer,
} from '../../viz/ThreeColumnVizControlsGrid';
import {
  GraphStatus as GraphStatusBase,
  GraphStatusCode,
  IGraphDispatchProps,
  productClassChoices,
  productDetailLevelChoices,
  UpdateType,
  VizType,
} from '../../viz/Utils';
import {
  CategorySelectorContainer,
  ChartHeader,
  HighlightContainer,
  SpinnerContainer,
  YearSelectorContainerWithoutPlayButton,
} from '../../viz/VizGrid';
import { vizSettingsPortalId } from '../../viz/VizSettings';
import {
  getHashInputFromRoutingAndUIState,
  IInputFromURLRouting,
  IUIState,
  MergedData,
} from '../../workerStore/feasibility/getReducer';
import {
  IComputationOutput,
  NodeSizing,
} from '../../workerStore/feasibility/Utils';
import {
  IMetadatum as ICountryMetadatum,
} from '../../workerStore/fetchedData/countryMetadata';
import {
  fetchIfNeeded as fetchCPY_C_IfNeeded,
} from '../../workerStore/fetchedData/countryProductYearByCountry';
import {
  fetchIfNeeded as fetch_CY_IfNeeded,
} from '../../workerStore/fetchedData/countryYear';
import {
  fetchIfNeeded as fetchLevelMappningIfNeeded,
  MappingType,
} from '../../workerStore/fetchedData/productLevelMapping';
import {
  fetchIfNeeded as fetchMetadataIfNeeded,
} from '../../workerStore/fetchedData/productMetadata';
import {
  fetchIfNeeded as fetchPYIfNeeded,
} from '../../workerStore/fetchedData/productYear';
import Chart, { Theme } from '../chart';
import {
  ExplorePageFeasibilityChartContainer as FeasibilityChartContainer,
  ExplorePageNonInteractiveChartContainer as NonInteractiveChartContainer,
  ExplorePageTooltipsContainer as TooltipsContainer,
} from '../Grid';
import {
  getMergedData,
  getUIState,
  getUpdateType,
  reset,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
} from '../reducer';
import {
  chartContainerMeasureToSVGMeasure,
  chartZoomPanDebounceInterval,
  ErrorCode,
  HideExports,
  YAxisMeasure,
} from '../Utils';
import ColoredBars from './ColoredBars';
import DetailOverlayContainer from './DetailOverlayContainer';
import Tooltips from './TooltipsContainer';
import { NewGraphTitle } from '../../viz/NewChartTitle';

const ProductCategorySelector = lazy(() => import(
  /* tslint:disable-next-line:trailing-comma*/
  /* webpackChunkName: "productClassSelector" */ '../../sharedComponents/categorySelector/ProductCategorySelector'
));
const Dropdown = styled(Select)`
  width: 100%;
`;

const hideExportsChoices: Array<IChoice<HideExports>> = [{
  value: HideExports.Off,
  label: __lexiconText('hideExportsSelector.off'),
}, {
  value: HideExports.On,
  label: __lexiconText('hideExportsSelector.on'),
}];

const nodeSizingChoices: Array<IChoice<NodeSizing>> = [{
  value: NodeSizing.None,
  label: __lexiconText('nodeSizingSelector.none'),
}, {
  value: NodeSizing.WorldTrade,
  label: __lexiconText('nodeSizingSelector.worldTrade'),
}];

const getNextGraphStatus = (
    nextMergedData: MergedData, prevGraphStatus: GraphStatus): GraphStatus => {

  let nextGraphStatus: GraphStatus;
  if (nextMergedData.hasError === true) {
    nextGraphStatus = {
      status: GraphStatusCode.Fail,
      value: nextMergedData.errorCode,
      loadableStatus: LoadableStatus.NotPresent,
    };
  } else {

    nextGraphStatus = determineNewGraphStatus<IComputationOutput, ErrorCode>(
      ErrorCode.NoData, prevGraphStatus, nextMergedData.value,
    );
  }
  return nextGraphStatus;
};

const getStateUpdater = (
    // Note: `unlaggedStateTransformer` is the state transformer function
    // usually passed into `setState` where we perform any change in "unlagged
    // state" here e.g. set IDs of highlighted products or products with
    // detailed overlay:
    unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
    // Default to `this.props` because most of the time, `updateState` is used
    // in place of `setState` and as such, `nextProps` haven't changed.
    nextProps: IProps,
  ) =>
    (prevState: IState): IState => {

// Note: have to call `setState` on `super` because we disable `setState` on
// this instance;
  const newUnlaggedState = unlaggedStateTransformer(prevState);

  const graphStatus = getNextGraphStatus(nextProps.computedData, prevState.graphStatus);
  const newState = {...newUnlaggedState, graphStatus};
  return newState as IState;
};

const removeTooltipFromState = (selectedProduct: string) => (prevState: IState): IState => {
  const newSelectedProducts = prevState.selectedProducts.filter(id => id !== selectedProduct);
  const detailedProduct = prevState.detailedProduct === selectedProduct ? undefined : prevState.detailedProduct;
  return {
    ...prevState, selectedProducts: [...newSelectedProducts], highlightedProduct: undefined, detailedProduct,
  };
};

export interface IOwnProps extends IInputFromURLRouting {
  onYearChange: (year: number) => void;
  onProductClassChange: (value: ProductClass) => void;
}

interface IStateProps extends IUIState {
  // Computed graph data:
  computedData: MergedData;
  updateType: UpdateType;

  countryMetadata: ILoadable<Map<number, ICountryMetadatum>>;
}

type IProps = IOwnProps & IStateProps & IGraphDispatchProps & TutorialModalProps;

interface IUnlaggedState {
  transformationMatrix: ITransformationMatrix;
  // This "delayed" transformation matrix is used for chart elemtns that are
  // allowed to "lag" behind the zoom/pan gesture (ticks, colored bars, tick
  // labes):
  delayedTransformationMatrix: ITransformationMatrix;
  delayedScaleFactor: number;

  hoveredProduct: string | undefined;
  // Product that's currently having the detail overlay:
  detailedProduct: string | undefined;

  selectedProducts: string[];
  highlightedProduct: string | undefined;

  mirrored: {
    country: IProps['country'];
    productClass: IProps['productClass'];
    detailLevel: IProps['detailLevel'];
    computedData: IProps['computedData'];
    year: IProps['year'];
  };
}

const checkIfNeedToReset = (nextProps: IProps, prevState: IState) => {

  const needToReset = (nextProps.productClass !== prevState.mirrored.productClass) ||
  (nextProps.detailLevel !== prevState.mirrored.detailLevel) ||
    (nextProps.computedData.hasError !== prevState.mirrored.computedData.hasError);
  return needToReset;
};

interface ComponentSnapshot {
  needToReset: boolean;
}

export type GraphStatus = GraphStatusBase<IComputationOutput, ErrorCode>;

interface IState extends IUnlaggedState {
  graphStatus: GraphStatus;
  showDataDownload: boolean;
  graphTitle: string;
}
class Feasibility extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const initialTransformationMatrix = getIdentityTransformMatrix();
    const unlaggedInitialState: IUnlaggedState = {
      transformationMatrix: initialTransformationMatrix,
      delayedTransformationMatrix: getIdentityTransformMatrix(),
      delayedScaleFactor: getScaleFactor(initialTransformationMatrix),
      hoveredProduct: undefined,
      highlightedProduct: undefined,
      detailedProduct: undefined,
      selectedProducts: [],
      mirrored: {
        country: props.country,
        queryLevel: props.queryLevel,
        productClass: props.productClass,
        detailLevel: props.detailLevel,
        computedData: props.computedData,
        year: props.year,
      },
    };

    const graphStatus = getNextGraphStatus(props.computedData, {
      status: GraphStatusCode.Initial,
      loadableStatus: LoadableStatus.Initial,
    });

    this.state = {
      ...unlaggedInitialState,
      graphStatus,
      showDataDownload: false,
      graphTitle: '',
    };
  }

  /* Start of setState-related methods */

  // Note: we disable `setState` because using `setState` directly will bypass
  // `updateState`, causing the UI controls to not work correctly e.g.
  // select/unselect categories will not cause the tree map to add/remove those
  // categories even though the category selector will still
  // highlight/unhighlight the icons of those categories:
  setState() {
    throw new Error('Do not call `setState` directly in this component. Use `updateState` instead.');
  }

  private updateState(
      // Note: `unlaggedStateTransformer` is the state transformer function
      // usually passed into `setState` where we perform any change in "unlagged
      // state" here e.g. set IDs of highlighted products or products with
      // detailed overlay:
      unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
      // Default to `this.props` because most of the time, `updateState` is used
      // in place of `setState` and as such, `nextProps` haven't changed.
      nextProps: IProps = this.props): void {

    // Note: have to call `setState` on `super` because we disable `setState` on
    // this instance;
    super.setState(getStateUpdater(unlaggedStateTransformer, nextProps));
  }
  /* End of setState-related methods */

  private fetchData({dispatchToWorkerStore, country, queryLevel, productClass, detailLevel}: IProps) {
    dispatchToWorkerStore(fetchMetadataIfNeeded({productClass}));
    dispatchToWorkerStore(fetchLevelMappningIfNeeded({productClass, mappingType: MappingType.Hi2Low}));
    dispatchToWorkerStore(fetchLevelMappningIfNeeded({productClass, mappingType: MappingType.Hi2Mid}));
    dispatchToWorkerStore(fetchPYIfNeeded({productClass}));
    if (country !== undefined) {
      dispatchToWorkerStore(fetchCPY_C_IfNeeded({country, queryLevel, productClass, level: detailLevel}));
    }
  }

  /* Start of lifecycle methods */
  componentDidMount() {
    // Register "resizer" method:
    resizeMeasurementListener.addListener(this.resizeListener);

    const {
      dispatchToBothStores, dispatchToWorkerStore, productClass, year, country, queryLevel,
      dispatchToMainStore,
    } = this.props;
    dispatchToWorkerStore(startSubscribing());
    dispatchToBothStores(updateInputFromURLRouting({productClass, year, country, queryLevel}, UpdateType.Hard));
    dispatchToWorkerStore(fetch_CY_IfNeeded({}));
    dispatchToMainStore(fetchCountryMetadataIfNeeded({}));
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    const {dispatchToBothStores, dispatchToWorkerStore} = this.props;
    // Clean up the UI state and derived data in the redux store:
    // and stop computing derived data:
    dispatchToWorkerStore(stopSubscribing());
    dispatchToBothStores(reset());
    // Unregister "resizer" method:
    resizeMeasurementListener.removeListener(this.resizeListener);
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    const mirroredState = {
      country: nextProps.country,
      queryLevel: nextProps.queryLevel,
      productClass: nextProps.productClass,
      detailLevel: nextProps.detailLevel,
      computedData: nextProps.computedData,
      year: nextProps.year,
    };
    const needToReset = checkIfNeedToReset(nextProps, prevState);
    let highlightUpdater: (prevState: IUnlaggedState) => IUnlaggedState;
    if (needToReset === true) {
      // Reset highlighted product if product classification changes:
      highlightUpdater =
        (inputPrevState: IUnlaggedState): IUnlaggedState => ({
          ...inputPrevState,
          highlightedProduct: undefined,
          detailedProduct: undefined,
          selectedProducts: [],
          hoveredProduct: undefined,
        });
    } else {
      highlightUpdater = identity;
    }

    const updatedState = getStateUpdater(highlightUpdater, nextProps)(prevState);
    return {
      ...updatedState,
      mirrored: mirroredState,
    };
  }

  getSnapshotBeforeUpdate(_prevProps: IProps, prevState: IState): ComponentSnapshot {
    const nextProps = this.props;
    const needToReset = checkIfNeedToReset(nextProps, prevState);
    return {needToReset};
  }

  componentDidUpdate(_unused: IProps, prevState: IState, snapshot: ComponentSnapshot) {
    const nextProps = this.props;
    if (nextProps.country !== prevState.mirrored.country ||
        nextProps.productClass !== prevState.mirrored.productClass) {

      this.fetchData(nextProps);
    }
    const {dispatchToBothStores} = nextProps;

    if (snapshot.needToReset === true) {
      dispatchToBothStores(updateUIState({deselectedCategories: []}, UpdateType.Hard));
    }
    // Trigger computation when props from routing changes:
    const {country, queryLevel, year, productClass} = nextProps;
    if (nextProps.country !== prevState.mirrored.country ||
        nextProps.productClass !== prevState.mirrored.productClass) {
      dispatchToBothStores(updateInputFromURLRouting({country, queryLevel, year, productClass}, UpdateType.Hard));
    } else if (nextProps.year !== prevState.mirrored.year) {
      dispatchToBothStores(updateInputFromURLRouting({country, queryLevel, year, productClass}, UpdateType.Soft));
    }
  }
  /* End of lifecycle methods */

  /* Start of UI state update methods */

  private setDeselectedCategories = (deselectedCategories: number[]) => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories}, UpdateType.Soft),
  )

  private resetDeselectedCategories = () => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories: []}, UpdateType.Soft),
  )

  private changeProductLevel =
    (detailLevel: Level) => this.props.dispatchToBothStores(updateUIState({detailLevel}, UpdateType.Soft))

  private changeYAxisMeasure =
    (yAxisMeasure: YAxisMeasure) => this.props.dispatchToBothStores(updateUIState({yAxisMeasure}, UpdateType.Soft))

  private changeNodeSizing =
    (nodeSizing: NodeSizing) => this.props.dispatchToBothStores(updateUIState({nodeSizing}, UpdateType.Soft))

  private changeHideExports =
    (hideExports: HideExports) => this.props.dispatchToBothStores(updateUIState({hideExports}, UpdateType.Soft))

  private updateTransformationMatrix = (matrix: ITransformationMatrix) => {
    this.updateDelayedTransformationRelatedState();
    this.updateState(state => ({...state, transformationMatrix: matrix}));
  }

  private updateDelayedTransformationRelatedState = debounce(() => {
    requestIdleCallback(() => {
      this.updateState(prevState => {
        const currentTransformationMatrix = prevState.transformationMatrix;
        return {
          ...prevState,
          delayedTransformationMatrix: currentTransformationMatrix,
          delayedScaleFactor: getScaleFactor(currentTransformationMatrix),
        };
      });
    });
  }, chartZoomPanDebounceInterval);

  private setHover = (hoveredProduct: string) =>
    this.updateState(prevState => ({ ...prevState, hoveredProduct}))

  private unsetHover = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, hoveredProduct: undefined}),
  )

  private onNodeClick = (inputId: string) => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => {
      const {selectedProducts} = prevState;
      const isPartOfSelection = selectedProducts.includes(inputId);

      // If clicked node is part of selection, remove it. Otherwise, add it to
      // selection:
      const newSelectedProducts = isPartOfSelection ?
                                    selectedProducts.filter(id => id !== inputId) :
                                    [...selectedProducts, inputId];
      const newState: IUnlaggedState = {
        ...prevState,
        selectedProducts: newSelectedProducts,
        // Also trigger detail overlay for the clicked node:
        detailedProduct: inputId,
      };
      return newState;

    },
  )

  private hideOverlay = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, detailedProduct: undefined}),
  )

  private resetAllMouseInteractiveElements = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({
       ...prevState,
       selectedProducts: [],
       highlightedProduct: undefined,
       detailedProduct: undefined,
      }),
  )

  private closeTooltip = (productId: string) => this.updateState(removeTooltipFromState(productId));

  private updateProductHighlight = (selection: INewDropdownOption | null) => {

      if (selection === null) {
        this.updateState(
          prevState => ({...prevState, highlightedProduct: undefined}),
        );
      } else {
        const {value} = selection;
        this.updateState(
          (state: IState) => ({...state, highlightedProduct: value}),
        );
      }
    }
  /* End of UI state update methods */

  /* Start of size measurement-related methods */

  private chartContainerEl: HTMLElement | null =  null;
  private rememberChartContainer = (el: HTMLElement | null) => {
    this.chartContainerEl = el;
    if (el) {
      this.measureDOMLayout();
    }
  }
  private chartRootEl: HTMLElement | null = null;
  private rememberChartRootEl = (el: HTMLElement | null) => this.chartRootEl = el;

  private measureDOMLayout() {
    if (this.chartContainerEl !== null) {
      const {
        width: decimalWidth,
        height: decimalHeight,
        top: decimalTop,
        left: decimalLeft,
      } = this.chartContainerEl.getBoundingClientRect();

      const {top, left, width, height} = chartContainerMeasureToSVGMeasure({
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
        top: Math.floor(decimalTop),
        left: Math.floor(decimalLeft),
      });
      this.props.dispatchToBothStores(updateUIState({
        svgHeight: height,
        svgWidth: width,
        svgTop: top,
        svgLeft: left,
      }, UpdateType.Hard));
    }
  }
  private resizeListener: IListener = this.getResizeListener();
  private getResizeListener(): IListener {
    let decimalWidth: number;
    let decimalHeight: number;
    let decimalTop: number;
    let decimalLeft: number;
    let originalPositioning: string;

    // Element that we need to remove from regular DOM layout flow so as to allow the
    // chart container to "relax" to its "natural" width:
    let target: HTMLElement | null;

    const before = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // Remember the original value of `position` and `height` so that we can restore them later:
        const computedStyle = window.getComputedStyle(target);
        originalPositioning = computedStyle.position!;
        if (originalPositioning !== null) {
          // ... then take the graph's root elemen out of the flow by setting its `position`
          // to `absolute`. This allows `this.chartContainerEl` to resize to is "natural"
          // width...
          target.style.position = 'absolute';
        }
      }
    };

    const measure = () => {
      const chartContainerEl = this.chartContainerEl;
      if (chartContainerEl !== null) {
        // ... which we measure (excluding the height)...
        const {width, top, left, height} = chartContainerEl.getBoundingClientRect();
        decimalWidth = width;
        decimalTop = top;
        decimalLeft = left;
        decimalHeight = height;

      }
    };

    const after = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // ... then restore the `position` and `height` to their original values:
        target.style.position = originalPositioning;

      }

      const {top, left, width, height} = chartContainerMeasureToSVGMeasure({
        top: Math.floor(decimalTop),
        left: Math.floor(decimalLeft),
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
      });
      this.props.dispatchToBothStores(updateUIState({
        svgWidth: width,
        svgHeight: height,
        svgTop: top,
        svgLeft: left,
      }, UpdateType.Hard));
    };

    return {
      before,
      measure,
      after,
    };
  }
  /* End of size measurement-related methods */

  //#region Exports-related methods
  private onShareClick = () => this.props.dispatchToMainStore(showShare(VizType.Feasibility));
  private onExportsClick = () => this.props.dispatchToMainStore(showExports(VizType.Feasibility));
  private onDataDownloadClick = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: true}),
    );
  }
  private dismissDataDownload = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: false}),
    );
  }
  //#endregion

  private updateGraphTitle = (val: string) => this.updateState(
    prevState => ({...prevState, graphTitle: val}),
  )

  /* End of size measurement-related methodss */
  render() {
    const props = this.props;
    const {
      onProductClassChange, updateType,
      hideExports,
      svgHeight, svgWidth,
      svgTop, svgLeft,
      yAxisMeasure, onYearChange,
      deselectedCategories,
      nodeSizing,
      dispatchToMainStore, countryMetadata,
    } = props;

    const {
      graphStatus,
      selectedProducts, highlightedProduct, hoveredProduct, detailedProduct,
      delayedTransformationMatrix, transformationMatrix, delayedScaleFactor,
      mirrored: {
        productClass, detailLevel, year, country, queryLevel
      },
    } = this.state;

    /* Start of loading spinner */
    let loadingSpinner: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Initial ||
        graphStatus.status === GraphStatusCode.LoadingAfterFailure ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      if (updateType === UpdateType.Hard) {
        loadingSpinner = <Loading/>;
      } else {
        loadingSpinner = null;
      }
    } else {
      loadingSpinner = null;
    }
    /* End of loading spinner */

    /* Start of error message */
    let errorOverlay: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Fail) {
      const {value} = graphStatus;
      let message: string;
      if (value === ErrorCode.NoData) {
        message = __lexiconText('error.noData');
      } else {
        message = __lexiconText('error.chooseCountry');
      }
      errorOverlay = (
        <ErrorOverlay message={message}/>
      );
    } else {
      errorOverlay = null;
    }
    /* End of error message */

    /* Start of "total"  and chart*/
    let totalElem: JSX.Element | null;
    let feasibilityChartElem: JSX.Element | null;
    let coloredBars: JSX.Element | null;
    let dataDownload: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      const {
        value: {
          nodes, total, yAverage, unfilteredTotal,
          xAxisMin, xAxisMax, yAxisMin, yAxisMax,
          tooltipMap,
        },
      } = graphStatus;

      totalElem = (
        <GraphTotal
          numerator={total}
          denominator={unfilteredTotal}
          totalGoods={unfilteredTotal}
          totalServices={null}
          hiddenCategories={getHiddenCategories(deselectedCategories, productClass)}
          selectedCategories={['product']}
          complexityGraph={true}
        />
      );

      feasibilityChartElem = (
        <Chart
          scaleFactor={delayedScaleFactor}
          nodes={nodes} selectedProducts={selectedProducts}
          highlightedProduct={highlightedProduct}
          hideExports={hideExports}
          svgHeight={svgHeight} svgWidth={svgWidth}
          topOffset={svgTop} leftOffset={svgLeft}
          updateTransformationMatrix={this.updateTransformationMatrix}
          onNodeMouseEnter={this.setHover}
          onNodeMouseLeave={this.unsetHover}
          onNodeClick={this.onNodeClick}
          onDoubleClick={this.resetAllMouseInteractiveElements}
          saveRootEl={this.rememberChartRootEl}
          nodeSizing={nodeSizing}
          transformationMatrix={transformationMatrix}
          yAverage={yAverage} year={year}
          yAxisMeasure={yAxisMeasure}
          xAxisMin={xAxisMin} xAxisMax={xAxisMax}
          yAxisMin={yAxisMin} yAxisMax={yAxisMax}
          onYAxisOptionSelect={this.changeYAxisMeasure}
          tooltipMap={tooltipMap}
          hoveredProduct={hoveredProduct}
          shouldNodeBeFadedByDefault={false}
          theme={Theme.Explore}
          productClass={this.props.productClass}
        />
      );

      if (this.state.showDataDownload
          && this.props.computedData.hasError === false
          && this.props.computedData.value.status === LoadableStatus.Present
        ) {
        const csvData: object[] = [];
        const categoryMap = productClass === ProductClass.HS ? categoryMapHS : categoryMapSITC;
        // check if first digit of code is NOT in deselectedCategories
        Object.keys(tooltipMap).forEach((key) => {
          const {shortLabel: Name, detailOverlayInfo, color} = tooltipMap[key];
          const targetSector = categoryMap.find(c => c.color === color);
          const sector = targetSector ? targetSector.sector : 0;
          const dataRows: {[label: string]: any} = {};
          if (!deselectedCategories.includes(sector)) {
            detailOverlayInfo.forEach(({label, value}) => {
              if (typeof value === 'string' || typeof value === 'number') {
                dataRows[label] = value;
              }
            });
            const Sector = targetSector ? targetSector.name : '';
            csvData.push({Name, ...dataRows, Sector});
          }
        });
        const title = this.state.graphTitle.length ? this.state.graphTitle : 'data';
        dataDownload = (
          <TriggerDataDownload
            data={csvData}
            graphTitle={title}
            closeOverlay={this.dismissDataDownload}
          />
        );
      } else {
        dataDownload = null;
      }

      if (svgHeight !== undefined && svgWidth !== undefined) {
        coloredBars = (
          <ColoredBars nodes={nodes} transformationMatrix={delayedTransformationMatrix}/>
        );
      } else {
        coloredBars = null;
      }
    } else {
      totalElem = (
        <GraphTotal
          numerator={0}
          denominator={0}
          totalGoods={0}
          totalServices={null}
          hiddenCategories={[]}
          selectedCategories={[]}
          complexityGraph={true}
        />
      );
      feasibilityChartElem = null;
      coloredBars = null;
      dataDownload = null;
    }
    /* End of "total" and chart*/

    /* Start of product highlight dropdown */
    let dropdownOptions: INewDropdownOption[];
    if (graphStatus.status === GraphStatusCode.Success) {
      ({value: {dropdownOptions}} = graphStatus);
    } else {
      dropdownOptions = [];
    }
    const highlightDropdown = (
      <HighlightDropdown
        label={__lexiconText('applicationWide.highlightDropdown.mainLabel')}
        tooltipText={__lexiconText('applicationWide.highlightDropdown.tooltipText')}
        options={dropdownOptions}
        value={highlightedProduct}
        isClearable={true}
        onChange={this.updateProductHighlight}
        DropdownComponent={Dropdown as any}
        DropdownContainerComponent={RightUpperControlContainer}
        />
    );
    /* End of product highlight dropdown */

    /* Start of tooltips */
    let tooltips: JSX.Element | null;
    // If there's tooltips to show, use the `TooltipsContainer` component:
    if ((graphStatus.status === GraphStatusCode.Success ||
          graphStatus.status === GraphStatusCode.LoadingAfterSuccess) &&
        (hoveredProduct !== undefined || highlightedProduct !== undefined || selectedProducts.length > 0)) {
      const {value: {tooltipMap}} = graphStatus;

      tooltips = (
        <Tooltips
          tooltipMap={tooltipMap} hoveredProduct={hoveredProduct}
          highlightedProduct={highlightedProduct} selectedProducts={selectedProducts}
          svgHeight={svgHeight} svgWidth={svgWidth} productClass={productClass}
          transformationMatrix={transformationMatrix}
          closeTooltip={this.closeTooltip}
        />
      );
    } else {
      tooltips = null;
    }
    /* End of tooltips */

    /* Start of detail overlay */
    let detailOverlay: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success && detailedProduct !== undefined) {
      const {value: {tooltipMap}} = graphStatus;
      detailOverlay = (
        <DetailOverlayContainer
          tooltipMap={tooltipMap} detailedProduct={detailedProduct} year={year}
          hideOverlay={this.hideOverlay}
        />
      );
    } else {
      detailOverlay = null;
    }
    /* End of detail overlay */

    /* Start of product class selector */
    const productClassSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
        choices={productClassChoices}
        selected={productClass}
        onClick={onProductClassChange}
      />
    );
    /* End of product class selector */

    /* Start of detail level selector */
    const detailLevelSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.productDetailLevelSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productDetailLevelSelector.mainLabel')}
        choices={productDetailLevelChoices}
        selected={detailLevel}
        onClick={this.changeProductLevel}
      />

    );
    /* End of detail level selector */

    const nodeSizingSelector = (
      <RadioSelector
        tooltipText={__lexiconText('nodeSizingSelector.tooltipText')}
        mainLabel={__lexiconText('nodeSizingSelector.mainLabel')}
        choices={nodeSizingChoices}
        selected={nodeSizing}
        onClick={this.changeNodeSizing}
      />

    );

    /* Start of "hide exports" selector */
    const hideExportsSelector = (
      <RadioSelector
        tooltipText={__lexiconText('hideExportsSelector.tooltipText')}
        mainLabel={__lexiconText('hideExportsSelector.mainLabel')}
        choices={hideExportsChoices}
        selected={hideExports}
        onClick={this.changeHideExports}
      />
    );
    /* End of "hide exports" selector */

    /* Start of year selector */
    const yearSelector = (
      <Timeline
        type={TimelineType.SingleYear}
        productClass={productClass} year={year} onYearChange={onYearChange}
      />
    );
    /* End of year selector */

    /* Start of category selector */
    const categorySelector = (
      <Suspense fallback={(<CategorySelectorContainer/>)}>
        <ProductCategorySelector
          deselectedCategories={deselectedCategories}
          productClass={productClass}
          setDeselected={this.setDeselectedCategories}
          resetDeselected={this.resetDeselectedCategories}
          isServicesEnabled={false}
          isServicesNotAvailableForAllYears={false}
        />
      </Suspense>
    );
    /* End of category selector */

    let dataNotesProps: DataNotesProps;
    if (country === undefined || countryMetadata.status !== LoadableStatus.Present || isNaN(country) || queryLevel !== QueryLevel.Location) {
      dataNotesProps = {
        isDataNotesWarningActive: false,
        launchDataNotes: () => dispatchToMainStore(
          showDataNotes([]),
        ),
      };
    } else {
      const {data} = countryMetadata;
      const retrievedMetadatum = data.get(country);
      if (retrievedMetadatum === undefined) {
        throw new Error('Cannot find metadatum for country' + country);
      }
      const showCountryNotes = !retrievedMetadatum.is_trusted;
      let isDataNotesWarningActive: boolean, dataNotes: DataIssueType[];
      if (showCountryNotes === true) {
        isDataNotesWarningActive = true;
        dataNotes = [DataIssueType.UnreliableCountry];
      } else {
        isDataNotesWarningActive = false;
        dataNotes = [];
      }
      dataNotesProps = {
        isDataNotesWarningActive,
        launchDataNotes: () => dispatchToMainStore(
          showDataNotes(dataNotes),
        ),
      };
    }

    const launchShare = country === undefined ? null : this.onShareClick;
    const launchExports = country === undefined ? null : this.onExportsClick;

    const share = (
      <Share
        launchShare={launchShare}
        launchExports={launchExports}
        showDataDownload={this.onDataDownloadClick}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={this.props.isTutorialModalOpen}
        setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
        closeDetailOverlay={this.resetAllMouseInteractiveElements}
      />
    );

    const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

    let vizSettings: React.ReactElement<any> | null;
    if (vizSettingsNodeRef) {
      vizSettings = ReactDOM.createPortal((
        <>
          {productClassSelector}
          {detailLevelSelector}
          {hideExportsSelector}
          {nodeSizingSelector}
        </>
      ), vizSettingsNodeRef);
    } else {
      vizSettings = null;
    }

    let titleInfo = {
      location: country,
      queryLevel: queryLevel,
      facet: DataFacet.CPY_C
    };

    let chartTitleElem: JSX.Element | null = null;
    if(titleInfo) {
      chartTitleElem = 
        <NewGraphTitle
        country={titleInfo.location}
        queryLevel={titleInfo.queryLevel}
        year={year}
        setTitle={(val: string) => this.updateGraphTitle(val)}
        vizType={VizType.Feasibility}
        facet={titleInfo.facet}
        graphTotal={totalElem}
      />
  
    }

    return (
      <>
        {chartTitleElem}
        {/* <ChartHeader>
          <ComplexityGraphTitle
            country={country}
            queryLevel={queryLevel}
            year={year}
            feasibility={true}
            setTitle={(val: string) => this.updateGraphTitle(val)}
          />
          <ChartShownTitle
            text={totalElem}
          />
        </ChartHeader> */}
        <NonInteractiveChartContainer>
          {coloredBars}
        </NonInteractiveChartContainer>
        <FeasibilityChartContainer ref={this.rememberChartContainer}>
          {feasibilityChartElem}
        </FeasibilityChartContainer>
        <SpinnerContainer>
          {loadingSpinner}
          {errorOverlay}
        </SpinnerContainer>
        <TooltipsContainer>
          {tooltips}
        </TooltipsContainer>
        {categorySelector}
        <HighlightContainer>
          {highlightDropdown}
        </HighlightContainer>
        <YearSelectorContainerWithoutPlayButton>
          {yearSelector}
        </YearSelectorContainerWithoutPlayButton>
        {vizSettings}
        {share}
        {detailOverlay}
        {dataDownload}
      </>
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getCountryMetadata = getMainThreadCountryMetadataSelector();

  return (rootState: IRootState, ownProps: IOwnProps) => {
    const hashInput = getHashInputFromRoutingAndUIState(ownProps, getUIState(rootState));
    const {
      detailLevel, hideExports, yAxisMeasure,
      deselectedCategories,
      svgHeight, svgWidth,
      svgTop, svgLeft,
      nodeSizing,
    } = getUIState(rootState);

    const computedData = getMergedData(rootState, hashInput);
    const countryMetadata = getCountryMetadata(rootState, {});
    return {
      detailLevel, hideExports, yAxisMeasure,
      deselectedCategories,
      svgHeight, svgWidth,
      svgTop, svgLeft,
      computedData,
      nodeSizing,
      updateType: getUpdateType(rootState),
      countryMetadata,
    };
  };
};

const mapDispatchToProps: MapDispatchToProps<IGraphDispatchProps, IOwnProps> =
  (dispatch: Dispatch) => {

    return {
      dispatchToWorkerStore: (action: AnyAction) => postMessageToMergeWorker(action),
      dispatchToMainStore: (action: AnyAction) => dispatch(action),
      dispatchToBothStores: (action: AnyAction) => {
        dispatch(action);
        postMessageToMergeWorker(action);
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Feasibility);
